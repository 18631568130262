body {
  padding: 0;
  height: 100vh;
  background: rgb(48, 48, 48);
}

.resumeView__container {
  padding: 20px;
  height: 100%;
  background: rgb(48, 48, 48);
}

.resumeView__box {
  display: block;
  visibility: visible;
  height: auto;
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.resumeView__redirect {
  padding-top: 50px;
  text-align: center;
  font-weight: 100;
  letter-spacing: normal;
  font-size: clamp(20px, 1.5vw, 40px);
  color: white;
}

.resumeView__button {
  background-color: Transparent;
  border: none;
  color: white;
  font-size: clamp(20px, 1.5vw, 40px);
  font-weight: 100;
  text-decoration: underline;
  padding-left: 5px;
}

.resumeView__button:hover {
  color: rgb(0, 251, 255);
  cursor: pointer;
}
