.footer__container {
  max-height: 200px;
  max-width: 100%;
}

.footer__down {
  position: absolute;
  margin-top: auto;
  padding-bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.footer__arrow {
  height: 40px;
  width: 40px;
  transition: all 0.2s ease-in-out;
}

.footer__arrow:hover {
  transform: scale(1.5);
}

.footer__icons {
  display: flex;
  justify-content: center;
}

.footer__logo {
  height: 40px;
  width: 40px;
  padding: 50px 30px 0 30px;
  transition: all 0.2s ease-in-out;
}

.footer__logo:hover {
  transform: scale(1.2);
}

.footer__text {
  text-align: center;
  padding: 10px;
  color: rgb(122, 118, 118);
  font-family: "opensans-bold", sans-serif;
  font-size: 16px;
  font-weight: 100;
}

.footer__name {
  color: rgb(0, 251, 255);
}

@media only screen and (max-width: 1100px) {
  .footer__down {
    padding-bottom: 37px;
  }
}
