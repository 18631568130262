.resume__education {
  background-color: rgb(204, 204, 203);
  color: rgb(48, 46, 46);
  font-family: "opensans-bold", sans-serif;
  padding: 1rem;
  text-align: center;
  font-size: 40px;
  font-weight: 800;
  font-style: italic;
}

.resume__container {
  background-color: rgb(156, 156, 153);
  color: rgb(48, 46, 46);
  font-family: "opensans-bold", sans-serif;
  padding: 1rem;
  text-align: center;
  font-size: 40px;
  font-weight: 800;
  font-style: italic;
}

.resume__lineTop {
  border: none;
  border-top: 6px solid rgb(74, 74, 74);
  width: 90%;
  max-width: 1200px;
}

.resume__job {
  max-width: 1200px;
  margin: auto;
  align-items: center;
}

.resume__info {
  min-width: 350px;
}

.resume__company {
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
}

.resume__title {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}

.resume__duration {
  font-size: 20px;
  font-style: italic;
  font-weight: 400;
  color: rgb(88, 87, 87);
}

.resume__lineInside {
  border: none;
  border-top: 2px solid rgb(74, 74, 74);
  width: 70%;
  max-width: 800px;
  animation: details-appear 0.5s ease-in forwards;
}

.resume__detailsButton {
  background-color: Transparent;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 100;
}

.resume__detailsButton:hover {
  color: rgb(0, 251, 255);
  cursor: pointer;
}

.resume__lineBottom {
  border: none;
  border-top: 4px solid rgb(74, 74, 74);
  width: 80%;
  max-width: 1000px;
}

.resume__spacer {
  content: ".";
  visibility: hidden;
  height: 30px;
}

.resume__responsibilities {
  text-align: left;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  animation: details-appear 0.5s ease-in forwards;
}

@keyframes details-appear {
  from {
    opacity: 0;
    transform: translateY(-1rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
