.thanks__container {
  width: 100%;
  height: auto;
  bottom: 0px;
  top: 0px;
  left: 0;
  position: absolute;
  background: linear-gradient(
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 1)
    ),
    url("../images/singapore.jpg");
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  text-align: center;
  background-size: cover;
  background-position: center;
  background-repeat: repeat-y;
  font-family: "opensans-bold", sans-serif;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.thanks__content {
  padding-bottom: 300px;
}

.thanks__text {
  font-weight: 800;
  letter-spacing: 15px;
  font-size: clamp(40px, 8vw, 150px);
  color: white;
}

.thanks_check {
  height: 10vw;
  width: 10vw;
  max-height: 200px;
  max-width: 200px;
  min-height: 100px;
  min-width: 100px;
}

.thanks__message {
  font-weight: 500;
  letter-spacing: normal;
  font-size: clamp(30px, 3vw, 80px);
  color: white;
}

.thanks__response {
  font-weight: 500;
  letter-spacing: normal;
  font-size: clamp(20px, 2vw, 70px);
  color: white;
}

.thanks__redirect {
  font-weight: 100;
  letter-spacing: normal;
  font-size: clamp(20px, 1.5vw, 60px);
  color: white;
}

.thanks__button {
  background-color: Transparent;
  border: none;
  color: white;
  font-size: clamp(20px, 1.5vw, 60px);
  font-weight: 100;
  text-decoration: underline;
}

.thanks__button:hover {
  color: rgb(0, 251, 255);
  cursor: pointer;
}

@media only screen and (max-width: 800px) {
  .thanks__content {
    padding-bottom: 100px;
  }

  .thanks__text {
    font-size: clamp(20px, 8vw, 90px);
  }

  .thanks_check {
    max-height: 100px;
    max-width: 100px;
    min-height: 50px;
    min-width: 50px;
  }

  .thanks__message {
    font-size: clamp(15px, 7vw, 50px);
  }

  .thanks__response {
    font-size: clamp(10px, 4vw, 40px);
    padding-left: 20px;
    padding-right: 20px;
  }

  .thanks__redirect {
    font-size: clamp(10px, 3vw, 40px);
  }

  .thanks__button {
    font-size: clamp(10px, 3vw, 40px);
  }
}
