.about__container {
  text-align: left;
  color: white;
  font-family: "opensans-bold", sans-serif;
  margin-left: 5vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about__picture {
  border: 0.5vh solid white;
  border-radius: 80%;
  margin-top: 10%;
  margin-bottom: 10%;
  display: block;
  height: auto;
  width: 80%;
  max-width: 500px;
  min-width: 150px;
}

.about__text {
  margin: 0 3vw;
  padding: 0;
  max-width: 900px;
}

.about__heading {
  font-size: clamp(40px, 4vw, 60px);
  font-style: italic;
  text-align: center;
  font-weight: 500;
  letter-spacing: 7px;
  /* text-decoration: underline; */
}

.about__blurb {
  line-height: 45px;
  font-size: 20px;
  font-weight: 100;
}

.about__info {
  display: flex;
  line-height: 40px;
}

.about__header {
  font-size: clamp(25px, 2vw, 40px);
  font-weight: 650;
}

.about__address {
  font-size: 20px;
  font-weight: 100;
}

.about__link {
  /* text-decoration: none; */
  color: white;
  font-weight: 100;
}

.about__link:hover {
  color: rgb(0, 251, 255);
}

.about__download {
  display: block;
  text-decoration: none;
  margin: auto;
  text-align: center;
}

.about__buttonView {
  height: 50px;
  font-size: 20px;
  background-color: #131314;
  border: none;
  color: white;
  cursor: pointer;
  font-weight: 800;
  padding: 10px 10px;
  margin-bottom: 20px;
  border-radius: 8px;
}

.about__buttonView:hover {
  color: rgb(0, 251, 255);
}

.about__hr {
  border: none;
}

.about__button {
  background-color: #131314;
  height: 50px;
  font-size: 20px;
  border: none;
  color: white;
  cursor: pointer;
  font-weight: 800;
  text-decoration: none;
  padding: 15px 10px;
  border-radius: 8px;
}

.about__button:hover {
  color: rgb(0, 251, 255);
}

.about__eye {
  height: 20px;
  width: auto;
  margin-right: 20px;
}

.about__icon {
  height: 20px;
  width: 20px;
  margin-right: 20px;
}

@media only screen and (max-width: 1100px) {
  .about__picture {
    display: none;
  }

  .about__info {
    display: block;
    line-height: 30px;
  }

  .about__text {
    margin-bottom: 2rem;
  }
}
